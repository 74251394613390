<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-dept
            type="search"
            label="관리부서"
            name="deptCd"
            :isFirstValue="false"
            v-model="searchParam.deptCd">
          </c-dept>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :comboItems="useFlagItems"
            label="LBLUSEFLAG"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            type="search"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="대기 배출구 목록"
      tableId="table"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="detailPopup" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'air-master-01',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'envAirMstOutletNm',
            field: 'envAirMstOutletNm',
            label: '배출구명',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          {
            name: 'envAirMstOutletSems',
            field: 'envAirMstOutletSems',
            label: '허가증상일련번호',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'envAirMstOutletName',
            field: 'envAirMstOutletName',
            label: '배출구일련번호',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'outletWidth',
            field: 'outletWidth',
            label: '배출구 종수',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'outletHeight',
            field: 'outletHeight',
            label: '법정측정횟수',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '관리부서',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          // {
          //   name: 'preventExemptionFlag',
          //   field: 'preventExemptionFlag',
          //   label: '방지시설면제',
          //   align: 'center',
          //   style: 'width: 100px',
          //   sortable: false,
          // },
          // {
          //   name: 'envAirMstPreventiveName',
          //   field: 'envAirMstPreventiveName',
          //   label: '연결방지시설',
          //   align: 'center',
          //   style: 'width: 200px',
          //   sortable: false,
          // },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.air.mst.outlet.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./airMaster01Detail.vue"}`);
      this.popupOptions.title = '대기 배출구 상세';
      this.popupOptions.param = {
        envAirMstOutletId: result ? result.envAirMstOutletId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
